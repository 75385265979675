<template>
  <v-container>
    <v-card>
      <v-toolbar flat color="blue" dark>
        <v-toolbar-title>File import</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card color="blue lighten-5" flat class="mx-6 mt-6">
        <v-card-text>
          <v-row dense>
            <v-col cols="8">
              <v-file-input
                v-model="gpxFile"
                prepend-icon="mdi-crosshairs-gps"
                show-size
                truncate-length="50"
                label="Select GPX file to import"
                :disabled="BonusLocationsCountGetter !== 0"
              ></v-file-input> </v-col
            ><v-col cols="4">
              <v-btn
                color="success"
                class="ma-4"
                elevation="0"
                :loading="gpxButton"
                :disabled="!gpxFile || BonusLocationsCountGetter !== 0"
                @click="importGpxFile()"
                ><v-icon left>mdi-file-import</v-icon>Import</v-btn
              >
              <v-btn
                color="error"
                class="ma-4"
                elevation="0"
                :loading="deleteButton"
                :disabled="BonusLocationsCountGetter === 0"
                @click="askClearImport()"
                ><v-icon left>mdi-delete-variant</v-icon>Clear</v-btn
              >
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="8">
              <v-file-input
                v-model="csvFile"
                prepend-icon="mdi-file-delimited"
                show-size
                truncate-length="50"
                label="Select CSV file to import"
                :disabled="BonusLocationsCountGetter === 0"
              ></v-file-input> </v-col
            ><v-col cols="4">
              <v-btn
                color="success"
                class="ma-4"
                elevation="0"
                :disabled="!csvFile || BonusLocationsCountGetter === 0"
                :loading="csvButton"
                @click="importCSVFile()"
                ><v-icon left>mdi-file-import</v-icon>Import</v-btn
              >
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="3" class="ml-8">
              <v-checkbox
                v-model="checkboxDescription"
                label="Import descriptions from CSV"
                :disabled="BonusLocationsCountGetter === 0"
                class="my-n3"
              ></v-checkbox> </v-col
            ><v-col cols="5">
              <v-checkbox
                v-model="checkboxComment"
                label="Import comments from CSV"
                :disabled="BonusLocationsCountGetter === 0"
                class="my-n3"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-dialog :value="importError" width="500px">
            <v-card>
              <v-card-title dense flat class="white red--text" primary-title>
                <v-icon color="red" class="mr-2"
                  >mdi-alert-circle-outline</v-icon
                >
                Error importing GPX file
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="mt-4">
                <div>
                  <p class="subtitle-1">
                    The import was aborted for the following reason:
                  </p>
                </div>
                <div class=".font-weight-black">
                  {{ importErrorMsg }}
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="importError = !importError">
                  Dismiss
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-text>
        <v-divider />
        <v-card-text class="mx-9"
          >CSV file has the following requirements
          <ul>
            <li>
              <strong>MUST</strong> be comma (,) separated, other separators not
              guaranteed to work correctly
            </li>
            <li><strong>MUST</strong> have a header row</li>
            <li>
              <strong>MUST</strong> have the following header fields
              <i>'Bonus'</i> and <i>'Points'</i>, capitalized as shown
            </li>
            <li>
              <strong>MAY</strong> have the following header fields
              <i>'Description'</i> and <i>'Comment'</i>, capitalized as shown
            </li>
          </ul>
        </v-card-text> </v-card
      ><v-card flat class="mx-2"
        ><v-card-text>
          <v-row dense>
            <v-col>
              <v-data-table
                v-if="BonusLocationsCountGetter"
                :headers="tableHeaders"
                :items="BonusLocationsRidingOnlyGetter"
                item-key="name"
                dense
                disable-pagination
                hide-default-footer
                class="blue lighten-5"
              >
                <template v-slot:item.name="{ item }">
                  <b>{{ item.name }}</b>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon small @click="deleteBonuslocation(item)"
                    >mdi-delete</v-icon
                  >
                </template>
                <template v-slot:item.longname="{ item }">
                  <v-icon v-if="item.longname" small color="green"
                    >mdi-check-bold</v-icon
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card>
    <dialogDelete
      v-model="showDeleteDialog"
      :objectid="objectidToBeDeleted"
      @delete="dialogDeleteAction"
    />
  </v-container>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import Papa from 'papaparse'
import { v4 as uuidv4 } from 'uuid'
import { BonusLocationsPrototype } from '@/components/prototypes/bonuslocations.js'
import dialogDelete from '@/components/dialogs/dialogDelete'
import { NotificationsPrototype } from '@/components/prototypes/notifications'
export default {
  name: 'FileImport',
  components: { dialogDelete },
  data() {
    const data = {
      objectidToBeDeleted: '',
      showDeleteDialog: false,
      gpxFile: null,
      csvFile: null,
      deleteButton: false,
      gpxButton: false,
      csvButton: false,
      checkboxDescription: false,
      checkboxComment: false,
      tableHeaders: [
        {
          text: '',
          align: 'center',
          sortable: true,
          class: 'blue lighten-5',
          value: 'longname'
        },
        {
          text: 'Location',
          align: 'start',
          sortable: true,
          class: 'blue lighten-5',
          value: 'name'
        },
        {
          text: 'Value',
          align: 'start',
          sortable: false,
          class: 'blue lighten-5',
          value: 'points'
        },
        {
          text: 'Latitude',
          sortable: false,
          class: 'blue lighten-5',
          value: 'latitude'
        },
        {
          text: 'Longitude',
          sortable: false,
          class: 'blue lighten-5',
          value: 'longitude'
        },
        {
          text: 'Description',
          sortable: false,
          class: 'blue lighten-5',
          value: 'description'
        },
        {
          text: 'Comment',
          sortable: false,
          class: 'blue lighten-5',
          value: 'comment'
        },
        {
          text: '',
          sortable: false,
          class: 'blue lighten-5',
          value: 'actions'
        }
      ],
      importError: false,
      importErrorMsg: ''
    }
    return { ...data }
  },
  computed: {
    ...mapState({
      CurrentUser: (state) => state.moduleUser.CurrentUser,
      UserProfile: (state) => state.moduleUser.UserProfile,
      BonusLocations: (state) => state.moduleBonusLocations.BonusLocations,
      Notifications: (state) => state.moduleNotifications.Notifications
    }),
    ...mapGetters('moduleBonusLocations', [
      'BonusLocationsCountGetter',
      'BonusLocationsRidingOnlyGetter',
      'BonusLocationsByNameGetter'
    ])
  },
  methods: {
    ...mapActions('moduleRouteStops', ['RouteStopsDeleteAllAction']),
    ...mapActions('moduleBonusLocations', [
      'BonusLocationsCreateUpdateAction',
      'BonusLocationsDeleteByCategoryAction',
      'BonusLocationsDeleteAction'
    ]),
    async importCSVFile() {
      const _this = this
      let start = Date.now()
      Papa.parse(this.csvFile, {
        header: true,
        complete: function (results, file) {
          for (let item in results.data) {
            let bonuslocation = {
              ..._this.BonusLocationsByNameGetter(results.data[item].Bonus)
            }
            bonuslocation.__proto__ = BonusLocationsPrototype.prototype
            if (bonuslocation !== null) {
              bonuslocation.points = Number(results.data[item].Points)
              if (
                results.data[item].Description !== null &&
                _this.checkboxDescription
              ) {
                bonuslocation.description = results.data[
                  item
                ].Description.replace(/[\W_-]+/g, ' ')
              }
              if (
                results.data[item].Comment !== null &&
                _this.checkboxComment
              ) {
                bonuslocation.comment = results.data[item].Comment.replace(
                  /[\W_-]+/g,
                  ' '
                )
              }
              _this.BonusLocationsCreateUpdateAction(bonuslocation)
            }
          }
          new NotificationsPrototype().create(
            'Import Files',
            'information',
            `Imported ${results.data.length} records from CSV file ${
              file.name
            } of type ${file.type} in ${(
              (Date.now() - start) /
              1000
            ).toLocaleString()} seconds.`
          )
        },
        error: function (error, file) {
          new NotificationsPrototype().create(
            'Import Files',
            'critical',
            `Error importing from CSV file ${file.name}. Error ${error.message} at row ${error.row}`
          )
          console.error('Error', error, file)
        },
        skipEmptyLines: true,
        dynamicTyping: true
      })
    },
    async importGpxFile() {
      this.gpxButton = true
      let start = Date.now()
      const _this = this
      let parseString = require('xml2js').parseString
      let reader = new FileReader()
      reader.readAsText(this.gpxFile)
      reader.onload = async () => {
        let fileData = reader.result
        await parseString(fileData, async function (err, result) {
          if (!err) {
            for (let item in result.gpx.wpt) {
              let bonuslocation = new BonusLocationsPrototype()
              bonuslocation.name = result.gpx.wpt[item].name[0]
              bonuslocation.bonusid = uuidv4()
              bonuslocation.latitude = Number(
                result.gpx.wpt[item].$.lat.replace(',', '.')
              )
              bonuslocation.longitude = Number(
                result.gpx.wpt[item].$.lon.replace(',', '.')
              )
              bonuslocation.description =
                result.gpx.wpt[item].desc === undefined
                  ? ''
                  : typeof result.gpx.wpt[item].desc[0] === 'string'
                  ? result.gpx.wpt[item].desc[0]
                  : ''
              bonuslocation.comment =
                result.gpx.wpt[item].cmt === undefined
                  ? ''
                  : typeof result.gpx.wpt[item].cmt[0] === 'string'
                  ? result.gpx.wpt[item].cmt[0]
                  : ''
              bonuslocation.category = 'A'
              _this.BonusLocationsCreateUpdateAction(bonuslocation)
            }
            new NotificationsPrototype().create(
              'Import Files',
              'information',
              `Imported ${result.gpx.wpt.length} waypoints from GPX file ${
                _this.gpxFile.name
              } in ${((Date.now() - start) / 1000).toLocaleString()} seconds.`
            )
            _this.gpxButton = false
          } else {
            _this.gpxButton = false
            new NotificationsPrototype().create(
              'Import Files',
              'critical',
              `Error importing from GPX file ${_this.gpxFile.name}. Error ${err}`
            )
            _this.importError = true
            _this.importErrorMsg = err
          }
        })
      }
    },
    async deleteBonuslocation(_bonusLocation) {
      confirm('Are you sure you want to delete this item?') &&
        (await this.BonusLocationsDeleteAction({
          bonus: _bonusLocation,
          rallyid: this.UserProfile.activerallyid
        }))
    },
    askClearImport() {
      this.objectidToBeDeleted = 'importedgpxfile'
      this.showDeleteDialog = true
    },
    async dialogDeleteAction(_event) {
      if (_event === 'GPX file') {
        this.deleteButton = true
        let start = Date.now()
        await this.RouteStopsDeleteAllAction()
        await this.BonusLocationsDeleteByCategoryAction({
          rallyid: this.UserProfile.activerallyid,
          categories: ['A', 'D', 'T', 'AC', 'DC', 'TC']
        })
        this.gpxFile = null
        new NotificationsPrototype().create(
          'Import Files',
          'information',
          `Cleared imported files in ${(
            (Date.now() - start) /
            1000
          ).toLocaleString()} seconds.`
        )
        this.deleteButton = false
      }
    }
  }
}
</script>
