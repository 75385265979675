import store from '@/store'

/**
 * @class
 * @property {String} message='' The actual message that needs to be displayed as part of the notification
 * @property {ISO8601} time=0 The time/date stamp of when the notification was generated
 * @property {information | warning | critical} level=information The severity level of the notification
 * @property {String} source='' The part of the application that is sending the notification
 * @property {Boolean} acknowledged=false Indicates if the notification has been acknowledge by the user (default: false)
 */
export function NotificationsPrototype(
  time = null,
  message = null,
  acknowledged = false,
  source = null,
  level = null
) {
  this.time = time
  this.message = message
  this.acknowledged = acknowledged
  this.source = source
  this.level = level
}

/**
 *
 * @param {String} _source=Global The part of the application that is sending the notification
 * @param {information | warning | critical} _level=information The severity level of the notification
 * @param {String} _message The actual message that needs to be displayed as part of the notification
 * @returns {Void}
 */
NotificationsPrototype.prototype.create = function (_source, _level, _message) {
  if (_message === null || _message === '') return
  if (_level !== 'information' || _level !== 'warning' || _level != 'critical')
    _level = 'information'
  if (_source === null || _source === '') _source = 'Global'
  this.time = Date.now()
  this.acknowledged = false
  this.level = _level
  this.message = _message
  this.source = _source
  store.dispatch('moduleNotifications/NotificationsCreateUpdateAction', this)
}
